export class DateHelpers {
  static msPerDay = 24 * 60 * 60 * 1000;

  static dateDiffInDays(a: Date, b: Date) {
    // Discard the time and time-zone information.

    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / this.msPerDay);
  }

  static addDaysToDate(date: Date, days: number) {
    const dateOffset = this.msPerDay * days;
    const newdate = new Date();

    newdate.setTime(date.getTime() + dateOffset);

    return newdate;
  }

  static dateDiffToTodayInDays(date: Date) {
    return this.dateDiffInDays(new Date(), date);
  }
}

interface MonthYearDateObj {
  _id: { year: number; month: number };
}
export const sortYearMonthIdObjDesc = (
  a: MonthYearDateObj,
  b: MonthYearDateObj,
) =>
  a._id.year < b._id.year
    ? -1
    : a._id.year > b._id.year
      ? 1
      : a._id.month < b._id.month
        ? -1
        : 1;
